import {Card} from "react-bootstrap";
import {ListGroup} from "react-bootstrap";
import ProductCardAttribute from "./ProductCardAttribute";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './own.css'
import {BrowserView, MobileView} from 'react-device-detect';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/public/images/' : 'https://api.dalbud.pl/public/images/'
const Child = (parentToChild: any) => {

    let product = parentToChild.parentToChild;

    let attributes: any = [];
    if (product['attributes']) {
        product['attributes'].forEach((element: any) => (attributes.push(element)));
    }


    return (
        <>
            <BrowserView style={{width: "18rem"}} className='m-2 ml-5 productContainer'>
                <Card style={{background: "#f0f4ec"}}>
                    <Card.Img variant="top" src={`${baseUrl}${product['file']}`}/>
                    <Card.Body>
                        <Card.Title>{product['name']}</Card.Title>
                        <Card.Text>
                            {product['description']}
                        </Card.Text>
                    </Card.Body>

                    <ListGroup className="list-group-flush">
                        {
                            attributes
                                .map((attribute: any) =>
                                    <ProductCardAttribute attribute={attribute}/>
                                )
                        }
                    </ListGroup>
                </Card>
            </BrowserView>
            <MobileView>
                <>
                    <Card style={{width: '18rem', background: "#f0f4ec"}} className="text-center">
                        <Card.Img variant="top" src={`${baseUrl}${product['file']}`}/>
                        <Card.Body>
                            <Card.Title>{product['name']}</Card.Title>
                            <Card.Text>
                                {product['description']}
                            </Card.Text>
                        </Card.Body>

                        <ListGroup className="list-group-flush">
                            {
                                attributes
                                    .map((attribute: any) =>

                                        <ProductCardAttribute attribute={attribute}/>
                                    )
                            }
                        </ListGroup>
                    </Card>
                </>

            </MobileView>
        </>
    );
}
export default Child;
