import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BrowserView, MobileView} from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormText} from "react-bootstrap";
import FetchOpeningHours from "./components/FetchOpeningHours";


export default class About extends React.Component {


    render() {
        return (
            <div id={"about"}>
                <BrowserView>
                    <div className="imgBannerText" id="Dojazd">
                        <img src={require("./onas.png")} alt=""/>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="imgBannerText-mobile" id="Dojazd">
                        <img src={require("./onas.png")} alt=""/>
                    </div>
                </MobileView>
                <BrowserView>
                    <div className="row">
                        <div className="col-4 mt-3 ps-5" >
                            <img src={require("./stojaklq.png")} className="img-fluid shadow  aboutImage" alt=""/>
                        </div>
                        <div className="col-8 mt-3 pe-3">
                            <div className="d-flex justify-content-center">
                                <h4>Tartak Dalbud funkcjonuje od 1995 roku. Specjalizujemy się w obróbce drewna i
                                    różnych
                                    wyrobów
                                    drzewnych,
                                    takich
                                    jak boazeria, więźby dachowe, łaty, deski budowlane, oraz inne niestandardowe na
                                    potrzeby
                                    klienta.</h4>
                            </div>
                            <div className="mt-4">
                                <div className="offset-2 col-8">
                                    <FetchOpeningHours></FetchOpeningHours>
                                </div>
                                <div className="offset-2  col-8 text-center pe-3 pt-3">
                                    <div className=" h6"><b>Przed przyjazdem prosimy o
                                        kontakt telefoniczny</b></div>
                                    <div className="mt-1"><b><a href="tel:502 658 602">502
                                        658
                                        602</a></b></div>
                                    <div className="mt-1"><b><a href="tel:601 658 602">601
                                        658
                                        602</a></b></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </BrowserView>
                <MobileView>
                    <div className="row">
                        <div className="col-10  offset-1 mt-3 ">
                            <img src={require("./stojaklq.png")} className="img-fluid shadow" alt=""/>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <div className="d-flex justify-content-center">
                                <h6>Tartak Dalbud funkcjonuje od 1995 roku. Specjalizujemy się w obróbce drewna i
                                    różnych
                                    wyrobów
                                    drzewnych,
                                    takich
                                    jak boazeria, więźby dachowe, łaty, deski budowlane oraz inne niestandardowe na
                                    potrzeby
                                    klienta.</h6>
                            </div>
                        </div>
                        <div className="col-12">
                            <FetchOpeningHours></FetchOpeningHours>
                            <div className="d-flex justify-content-center text-center h5 mt-3"><b>Przed
                                przyjazdem prosimy o
                                kontakt telefoniczny</b></div>
                            <div className="d-flex justify-content-center mt-1"><b><a href="tel:502 658 602">502 658
                                602</a></b>

                            </div>
                            <div className="d-flex justify-content-center mt-1"><b><a href="tel:601 658 602">601 658
                                602</a></b></div>

                        </div>
                    </div>

                </MobileView>
            </div>
        )
    }
}