import React, { useState, useEffect } from 'react';
import NewsList from "../NewsList";
import axios from "axios";
import NewsListMobile from "../NewsListMobile";
import {BrowserView, MobileView} from "react-device-detect";
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/news' : 'https://api.dalbud.pl/api/news?v=1.0'

const NewsContainer = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function fetchData() {
            try{
                const res = await axios.get(baseUrl,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                setNews(res.data);
                setIsLoading(false);
            } catch (error) {
                // window.location.replace("http://dalbud.pl");
                // setIsLoading(true);

            }
        }
        fetchData();
    }, []);

    if (isLoading) return <p>Loading...</p>;
    return (

        <div>
            <BrowserView>
            <NewsList messages={news} />
            </BrowserView>
            <MobileView>
                <NewsListMobile messages={news} />
            </MobileView>
        </div>
    );
}
export default NewsContainer;