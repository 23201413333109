import React from 'react';
import axios from 'axios';
import Category from "./Category";
import "bootstrap/dist/css/bootstrap.min.css";
import './own.css';
import {Button} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"
import {BrowserView, MobileView} from 'react-device-detect';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/products' : 'https://api.dalbud.pl/api/products?v=1.0'
export default class Opening extends React.Component {

    state = {
        categories: []
    }

    componentDidMount() {
        axios.get(baseUrl,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then(res => {
                const categories = res.data;
                this.setState({categories});
            })
    }



    render() {

        return (

            <>

                <BrowserView>
                    <div className="imgBannerText" id="Dojazd">
                        <img src={require("./produkty.png")} alt=""/>
                    </div>
                    {
                        this.state.categories
                            .map(category =>
                                <div key={category['id']}>
                                    <div className="d-flex justify-content-center">
                                        <div className='h3'>{category['name']}</div>
                                        <Button variant="success" style={{marginLeft: "10px"}}>
                                            {category['countProducts']}
                                            <i className='bi bi-chevron-double-down'></i>
                                        </Button>
                                    </div>
                                    <Category parentToChild={category} key={category['id']} className="row"/>

                                </div>
                            )
                    }
                </BrowserView>
                <MobileView>
                    <div className="imgBannerText-mobile" id="Dojazd">
                        <img src={require("./produkty.png")} alt=""/>
                    </div>
                    <>
                        {
                            this.state.categories
                                .map(category =>
                                    <div key={category['id']}>
                                        <div className="d-flex justify-content-center">
                                            <div className='h2'>{category['name']}</div>
                                            <Button variant="success" style={{marginLeft: "10px"}}>
                                                {category['countProducts']}
                                            </Button>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <Category parentToChild={category} key={category['id']}
                                                      className="row text-center"/>
                                        </div>
                                    </div>
                                )
                        }

                    </>


                </MobileView>
            </>
        )
    }
}