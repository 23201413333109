import {ListGroup} from "react-bootstrap";


const ChildAttribute = (attribute:any) => {

    return (
        <>
            <ListGroup.Item style={{background:"rgba(222,231,219,0.48)"}}>{attribute.attribute}</ListGroup.Item>
        </>
    );


}

export default ChildAttribute;