import React from 'react';
import './App.css';
import {Navbar} from "react-bootstrap";
import {Container} from "react-bootstrap";
import {Nav} from "react-bootstrap";
import {NavDropdown} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import {MobileView, BrowserView} from "react-device-detect";

function NavbarMenu() {
    return (
        <Navbar expand="lg" sticky={"top"} className="navbar">
            <Container>
                <Navbar.Brand href="/#home">
                    <BrowserView>
                        <img src={require("./logo_napis.png")} alt=""  style={{height:"110px"}}/>
                    </BrowserView>
                    <MobileView>
                        <img src={require("./logo_napis.png")} alt=""  style={{height:"100px"}}/>
                    </MobileView>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link style={{fontSize:"30px"}} href="/#carousel">Strona główna</Nav.Link>
                        <Nav.Link style={{fontSize:"30px"}} href="/#about">O nas</Nav.Link>
                        <Nav.Link style={{fontSize:"30px"}}  href="/#products">Nasze produkty</Nav.Link>
                        <Nav.Link style={{fontSize:"30px"}}  href="/#contact">Kontakt</Nav.Link>

                        {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                        {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item href="#action/3.2">*/}
                        {/*        Another action*/}
                        {/*    </NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Divider/>*/}
                        {/*    <NavDropdown.Item href="#action/3.4">*/}
                        {/*        Separated link*/}
                        {/*    </NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarMenu;
