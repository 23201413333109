import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {BrowserView, MobileView} from "react-device-detect";
import ProductCard from "./ProductCard";

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/news' : 'https://api.dalbud.pl/api/news'
const baseUrlImages = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/public/images/' : 'https://api.dalbud.pl/public/images/'

function SingleNewsMobile() {
    const [singleNews, setSingleNews] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {slug} = useParams();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const res = await axios.get(`${baseUrl}?slug=${slug}&v=1.0`);
                setSingleNews(res.data[0]);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(true);
                console.error(error);
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i) ?? 'xd';
                    const value = localStorage.getItem(key);
                    alert('key'+'value');

                }
            }
        }

        fetchData();
    }, [slug]);

    if (isLoading) return <p>Loading...</p>;

    const paragraphs = (singleNews as any).description.split("<br><br>");


    return (
        <div>


            <div className="single-news-title">{(singleNews as any).title}</div>
            <div className="single-news-container">

                {
                    paragraphs.map((paragraph: any, key: any) => (
                        <div key={key}>


                            {key === 1 && <div>
                                {!isLoading && (singleNews as any).files && (
                                    <img id="img1" className="singleNews-img basic-img"
                                         src={`${baseUrlImages}${(singleNews as any).files[0]}`}

                                    />
                                )}
                            </div>}

                            {key === 3 && <div>
                                {!isLoading && (singleNews as any).files && (
                                    <img id="img2" className="singleNews-img basic-img"
                                         src={`${baseUrlImages}${(singleNews as any).files[1]}`}

                                    />

                                )}
                            </div>}

                            <div className="singleNews-par" dangerouslySetInnerHTML={{__html: paragraph}}/>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default SingleNewsMobile;