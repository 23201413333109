import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 50px;
  padding: 40px 10px;
  background: #CEDCC3;
  bottom: 0;
  width: 100%;


  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`;

export const Row = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(auto-fill, 
                         minmax(385px, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a`
  color: #000000;
  margin-bottom: 10px;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: #000000;
    transition: 200ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const ColumnDescription = styled.p`
  //display: flex;
  
  text-align: left;
  margin-left: 10px;
`;

export const Description = styled.p`
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
`;
