import React, {Component} from 'react';
import {MobileView, BrowserView} from "react-device-detect";

interface ContactFormState {
    name: string;
    email: string;
    phone: string;
    message: string;
    errors: {
        [key: string]: string;
    };
}

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : "https://api.dalbud.pl"

export default class ContactForm extends Component<{}, ContactFormState> {
    constructor(props: any) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            errors: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        } as Pick<ContactFormState, keyof ContactFormState>);
    }



    validateEmail(email: any) {
        // prosty wzór do sprawdzania poprawności adresu email
        const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
        return emailRegex.test(email);
    }

    validatePhone(phone: any) {
        // prosty wzór do sprawdzania poprawności numeru telefonu
        const phoneRegex = /^\d{9}$/;
        return phoneRegex.test(phone);
    }

    validateForm = (): { [key: string]: string } => {
        const errors: { [key: string]: string } = {};
        const { name, email, phone, message } = this.state;

        if (!name) {
            errors.name = 'Pole Imię jest wymagane.';
        }
        if (!email && !phone) {
            errors.email = 'Podaj swój adres e-mail lub numer telefonu.';
            errors.phone = 'Podaj swój adres e-mail lub numer telefonu.';
        } else if (email && !/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Wprowadź poprawny adres email.';
        } else if (phone && !/^\d{9}$/.test(phone)) {
            errors.phone = 'Wprowadź poprawny numer telefonu (9 cyfr).';
        }
        if (!message || message.length < 10) {
            errors.message = 'Wiadomość musi zawierać co najmniej 10 znaków.';
        }

        return errors;
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = this.validateForm();
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            return;
        }
        const { name, email, phone, message } = this.state;
        const data = { name, email, phone, message };
        fetch(baseUrl+"/api/message?v=1.0", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.ok) {
                    alert('Dane zostały wysłane.');
                    this.setState({ name: '', email: '', phone: '', message: '', errors: {} });
                } else {
                    throw new Error('Wystąpił błąd podczas wysyłania danych.');
                }
            })
            .catch(error => {
                console.error(error);
                alert('Wystąpił błąd podczas wysyłania danych.');
            });
    };

    render() {
        return (
            <>
                <BrowserView>
                    <div>
                        <div className="d-flex justify-content-center mt-1">
                            <h2>Formularz kontaktowy</h2>
                        </div>
                        <div className="d-flex justify-content-center">
                            <form onSubmit={this.handleSubmit} className="col-6 mt-2">
                                <div className="form-group">
                                    <label htmlFor="name">Imię:</label>
                                    <input type="text" name="name" value={this.state.name} onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.name &&
                                        <div className="invalid-feedback d-block">{this.state.errors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" name="email" value={this.state.email}
                                           onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.email &&
                                        <div className="invalid-feedback d-block">{this.state.errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Nr telefonu:</label>
                                    <input type="tel" name="phone" value={this.state.phone} onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.phone &&
                                        <div className="invalid-feedback d-block">{this.state.errors.phone}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Tekst zapytania:</label>
                                    <textarea name="message" value={this.state.message} onChange={this.handleChange}
                                              className="form-control"></textarea>
                                    {this.state.errors.message &&
                                        <div className="invalid-feedback d-block">{this.state.errors.message}</div>}
                                </div>
                                <button type="submit" className="btn btn-outline-success mt-3 mb-3">Wyślij</button>
                            </form>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div>
                        <div className="d-flex justify-content-center mt-1">
                            <h2>Formularz kontaktowy</h2>
                        </div>
                        <div className="d-flex justify-content-center">
                            <form onSubmit={this.handleSubmit} className="col-10 mt-2">
                                <div className="form-group">
                                    <label htmlFor="name">Imię:</label>
                                    <input type="text" name="name" value={this.state.name} onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.name &&
                                        <div className="invalid-feedback d-block">{this.state.errors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" name="email" value={this.state.email}
                                           onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.email &&
                                        <div className="invalid-feedback d-block">{this.state.errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Nr telefonu:</label>
                                    <input type="tel" name="phone" value={this.state.phone} onChange={this.handleChange}
                                           className="form-control"/>
                                    {this.state.errors.phone &&
                                        <div className="invalid-feedback d-block">{this.state.errors.phone}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Tekst zapytania:</label>
                                    <textarea name="message" value={this.state.message} onChange={this.handleChange}
                                              className="form-control"></textarea>
                                    {this.state.errors.message &&
                                        <div className="invalid-feedback d-block">{this.state.errors.message}</div>}
                                </div>
                                <button type="submit" className="btn btn-outline-success mt-3 mb-3">Wyślij</button>
                            </form>
                        </div>
                    </div>
                </MobileView>
            </>
        );
    }

}