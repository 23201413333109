import React from 'react';

import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
    Description,
    ColumnDescription

} from "./FooterStyles";

const Footer = () => {
    return (
        <Box>
            <Container>
                <Row>
                    <ColumnDescription>
                        <Heading>TARTAK CZĘSTOCHOWA</Heading>
                        <Description>Tartak Dalbud to tartak przy ul. Piastowskiej w Częstochowie oferujący różnego typu
                            drewno, boazerię, więźby dachowe, łaty, lamele, płyty osb półbali
                            oraz desek tarasowych i podobne wyroby drzewne</Description>
                    </ColumnDescription>
                    <Column>
                        <Heading>Skróty:</Heading>
                        <FooterLink href="/#carousel">Strona główna</FooterLink>
                        <FooterLink href="/#about">O nas</FooterLink>
                        <FooterLink href="/#products">Produkty</FooterLink>
                        <FooterLink href="/#contact">Kontakt</FooterLink>

                    </Column>
                    {/*<Column>*/}
                    {/*    <Heading>test1</Heading>*/}
                    {/*    <FooterLink>test1</FooterLink>*/}
                    {/*    <FooterLink>test1</FooterLink>*/}
                    {/*    <FooterLink>test1</FooterLink>*/}
                    {/*</Column>*/}
                </Row>
            </Container>
        </Box>
    );
};

export default Footer;