import React, {useEffect, useState} from "react";
import axios from "axios";
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/openinghours' : 'https://api.dalbud.pl/api/openinghours?v=1.0'

export default function FetchOpeningHours() {
    const [openingHours, setOpeningHours] = useState([])

    useEffect(() => {
        axios.get(baseUrl,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then(res => {
                setOpeningHours(res.data);
            })
    }, [])

    return (

        <>{
            <div className="d-flex justify-content-center">
                <table>
                    <tbody>
                    {
                        openingHours.map(openingHour =>
                            <tr  key={openingHour['day']} >
                                <th>{openingHour['day']}</th>
                                <td className="p-1">{openingHour['openAt']}-{openingHour['closeAt']}</td>
                                {openingHour['additionalInformation'] !== null &&
                                    <td className="p-1 fw-bold">{openingHour['additionalInformation']}</td>

                                }
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        }
        </>
    )

}