import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
const baseUrlImages = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/public/images/' : 'https://api.dalbud.pl/public/images/'

const SingleNewsPreview = (message: any) => {
    let singleNews = message.message;
    const {slug, title, description, files } = singleNews;
    const shortDescription = typeof description === 'string' ? description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 100) + '...' : '';

    return (
        <div className="singleNews-preview-mobile">
            <img src={`${baseUrlImages}${files[0]}`}/>

            <div>
                <h2>{title}</h2>
                <p>{shortDescription} <Link to={{
                    pathname: `/${slug}`
                }}>Czytaj dalej</Link></p>
            </div>
        </div>
    );
}

const NewsList = (messages: any) => {
    const [visibleMessages, setVisibleMessages] = useState(messages.messages.slice(0, 1));
    const [showMoreButton, setShowMoreButton] = useState(true);

    useEffect(() => {
        if (visibleMessages.length >= messages.messages.length) {
            setShowMoreButton(false);
        } else {
            setShowMoreButton(true);
        }
    }, [visibleMessages, messages.messages]);

    const handleShowMore = () => {
        setVisibleMessages((prev:any) => [...prev, ...messages.messages.slice(prev.length, prev.length + 1)]);
    }

    return (
        <div className="news-list-mobile">
            {visibleMessages.map((message:any) => (
                <SingleNewsPreview message={message} key={message['id']}/>
            ))}
            {visibleMessages.length < messages.messages.length && (
                <div className="show-more-button">
                    <button className='showMoreBtn' onClick={handleShowMore}>Pokaż więcej</button>
                </div>
            )}
        </div>
    );
}

export default NewsList;
