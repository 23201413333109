import React, { Component } from 'react';
import './App.css';
import NavbarL from './Navbar'
import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from "./Footer";
import setupLocatorUI from '@locator/runtime';
import Opening from "./Opening";
import About from "./About";
import OptionalDelivery from "./OptionalDelivery";
import { BrowserView, MobileView } from 'react-device-detect';
import LocationCompany from "./LocationCompany";
import ContactForm from "./ContactForm";
import CarouselEntry from "./CarouselEntry";
import News from "./News";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import SingleNews from "./SingleNews";

export default class Main extends Component {
    render() {
        return (
                <div>
                    <BrowserView>
                        <div>
                            <CarouselEntry></CarouselEntry>
                            <News></News>
                            <About></About>
                            <Opening></Opening>
                            <OptionalDelivery></OptionalDelivery>
                            <LocationCompany></LocationCompany>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <CarouselEntry></CarouselEntry>
                        <div>
                            <News></News>
                            <About></About>
                            <Opening></Opening>
                            <OptionalDelivery></OptionalDelivery>
                            <LocationCompany></LocationCompany>
                        </div>
                    </MobileView>
                    <ContactForm></ContactForm>
                </div>

        );
    }
}