import React from 'react';
import {Carousel, CarouselItem} from "react-bootstrap";
import {BrowserView, MobileView} from 'react-device-detect';

const itemsBrowser = [
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '350px'}}>
            <img src={require("./carousel/ii1.jpg")} className="d-block w-100" alt="First slide"/>
            <Carousel.Caption>
                <h1>Szeroki wybór towarów</h1>
            </Carousel.Caption>
        </div>
    </CarouselItem>,
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '350px'}}>
            <img src={require("./carousel/ii3.jpg")} className="d-block w-100" alt="Second slide"/>
            <Carousel.Caption>
                <h1>Błyskawiczny transport</h1>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
            </Carousel.Caption>
        </div>
    </CarouselItem>,
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '350px'}}>
            <img src={require("./carousel/ii2.jpg")} className="d-block w-100" alt="Third slide" style={{opacity: "0.9"}}/>
            <Carousel.Caption>
                <h1>Bardzo dobra jakość</h1>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
                <p className="invisible"> .</p>
            </Carousel.Caption>
        </div>
    </CarouselItem>

];

const itemsMobile = [
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '170px'}}>
            <img src={require("./carousel/ii1.jpg")} className="d-block w-100" alt="First slide"/>
            <Carousel.Caption>
                <h3>Szeroki wybór towarów</h3>
                <p className="invisible"> .</p>
            </Carousel.Caption>
        </div>
    </CarouselItem>,
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '170px'}}>
            <img src={require("./carousel/ii2.jpg")} className="d-block w-100" alt="Second slide"/>
            <Carousel.Caption>
                <h3>Błyskawiczny transport</h3>
            </Carousel.Caption>
        </div>
    </CarouselItem>,
    <CarouselItem>
        <div className="d-flex justify-content-center align-items-center" style={{height: '170px'}}>
            <img src={require("./carousel/ii3.jpg")} className="d-block w-100" alt="Third slide"/>
            <Carousel.Caption>
                <h3>Bardzo dobra jakość</h3>
                <p className="invisible"> .</p>
            </Carousel.Caption>
        </div>
    </CarouselItem>

];


const CarouselEntry = () => {
    return (
        <>
        <BrowserView>
        <div className="p-2" id={"carousel"}>
            <div className="offset-1 col-10 shadow ">
                <Carousel
                    slide={true}
                    controls={false}
                    interval={2500}
                    fade={true}
                    indicators={false}
                    // wrap={true}
                >
                    {itemsBrowser}
                </Carousel>
            </div>
        </div>
        </BrowserView>
        <MobileView>
            <div className="pt-0 pb-2" id={"carousel"}>
                <div className="">
                    <Carousel
                        slide={true}
                        controls={false}
                        interval={2500}
                        fade={true}
                        indicators={false}
                        // wrap={true}
                    >
                        {itemsMobile}
                    </Carousel>
                </div>
            </div>
        </MobileView>
        </>
    );
};

export default CarouselEntry;