import React, { Component } from 'react';
import './App.css';
import NavbarL from './Navbar'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import SingleNews from "./SingleNews";
import SingleNewsMobile from "./SingleNewsMobile";
import Main from "./Main";
import { BrowserView, MobileView} from "react-device-detect";
import Footer from "./Footer";
import Helmet from "react-helmet";
import ReactGA from 'react-ga';
import CookieConsent from 'react-cookie-consent';

const urlCanon = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/' : "https://dalbud.pl/"

export default class App extends Component {

    render() {
        return (
            <div>
                <div className="pe-">
                    <CookieConsent
                        location="bottom"
                        buttonText="Zgoda"
                        cookieName="myAwesomeCookieName2"
                        style={{
                            background: '#A7B99E',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: "center",
                            margin: "0 auto"

                        }}
                        buttonStyle={{color: 'black', fontSize: '17px', background: "#CEDCC3"}}
                        expires={150}
                    >
                        Ta strona korzysta z plików cookies oraz Google Analytics w celu śledzenia aktywności
                        użytkowników i poprawy jakości świadczonych usług. <br/>

                    </CookieConsent>
                </div>
                <Helmet>
                    <title>Dalbud - Tartak, drewno, płyty osb, boazeria, lamele</title>
                    <meta name="description" content="Tartak Dalbud to tartak przy ul. Piastowskiej w Częstochowie oferujący różnego typu drewno,
       boazerię, więźby dachowe, łaty, lamele,płyty osb półbali oraz desek tarasowych i podobne wyroby drzewne"/>
                    <meta name="keywords" content="Tartak, drewno, płyty osb, boazeria, lamele, drzewo, osb"/>
                    <meta name="robots" content="index,follow"/>
                    <link rel="canonical" href={urlCanon}/>
                </Helmet>

                <div style={{background: "#A7B99E"}}>

                    <BrowserRouter>

                        <NavbarL></NavbarL>

                        <BrowserView>
                            <div className='mainContainerBrowser'>
                                <Routes>
                                    <Route path="/" element={<Main/>}/>
                                </Routes>
                            </div>
                            <div className='mainContainerBrowser-News'>
                                <Routes>
                                    <Route path="/:slug" element={<SingleNews/>}/>
                                </Routes>
                            </div>
                        </BrowserView>

                        <MobileView>
                            <div className='mainContainerMobile'>
                                <Routes>
                                    <Route path="/" element={<Main/>}/>
                                    <Route path="/:slug" element={<SingleNewsMobile/>}/>
                                </Routes>
                            </div>
                        </MobileView>
                        <Footer></Footer>
                    </BrowserRouter>
                </div>
            </div>
        );
    }
}