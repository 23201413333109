import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BrowserView, MobileView} from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormText} from "react-bootstrap";
import FetchOpeningHours from "./components/FetchOpeningHours";


export default class OptionalDelivery extends React.Component {


    render() {
        return (
            <>
                <div className="col-12 text-center h2 mt-2" id="Dojazd">Możliwy transport do okolic:</div>
                <div className="row">
                    <div className="col-2 deliveryBorderHide"></div>
                    <div className="col-8 deliveryBorderShow"></div>
                    <div className="col-2 deliveryBorderHide"></div>
                </div>

                <div className="d-flex justify-content-center">
                    <table className="h3">
                        <tr>
                            <td className="p-3 font-weight-light">Blachownia</td>
                            <td className="p-3 font-weight-light">Poczesna</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-weight-light">Kłobuck</td>
                            <td className="p-3 font-weight-light">Mykanów</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-weight-light">Olsztyn</td>
                            <td className="p-3 font-weight-light">Konopiska</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-weight-light">Rędziny</td>
                            <td className="p-3 font-weight-light">Mstów</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-weight-light">Poraj</td>
                            <td className="p-3 font-weight-light">Myszków</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-weight-light">Wręczyca Wielka</td>
                        </tr>
                    </table>

                </div>
            </>
        )
    }
}