import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {BrowserView, MobileView} from "react-device-detect";
import ProductCard from "./ProductCard";
import {Helmet} from "react-helmet";

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/news' : 'https://api.dalbud.pl/api/news'
const baseUrlImages = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/public/images/' : 'https://api.dalbud.pl/public/images/'
const urlCanon = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/' : "https://dalbud.pl/"

function SingleNews() {
    const [singleNews, setSingleNews] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {slug} = useParams();
    const [isImageRendered, setIsImageRendered] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const res = await axios.get(`${baseUrl}?slug=${slug}`);
                setSingleNews(res.data[0]);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(true);
                console.error(error);
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i) ?? 'xd';
                    const value = localStorage.getItem(key);
                    alert(key+value);

                }
                // Pobierz wszystkie ciasteczka
            }
        }

        fetchData();
    }, [slug]);

    if (isLoading) return <p>Loading...</p>;

    const paragraphs = (singleNews as any).description.split("<br><br>");
    const hrefCanon = urlCanon+(singleNews as any).slug

    if(isImageRendered){
        const img1 = document.getElementById('img1') as HTMLImageElement;
        const img2 = document.getElementById('img2') as HTMLImageElement;

        if (img1) {
            const aspectRatio = img1.naturalWidth / img1.naturalHeight;
            let parentDiv = (img1.parentElement as any).parentElement;
            if (aspectRatio < 1) {

                if(parentDiv){
                    let nextDiv = parentDiv.nextElementSibling;
                    (nextDiv as any).classList.add("cleared");
                }
                img1.classList.add('vertical-image');
                img1.classList.add('nice-img');

            } else {
                img1.classList.add('horizontal-image');
                console.log('zdjecie1poziome');
            }
        }
        if (img2) {
            const aspectRatio = img2.naturalWidth / img2.naturalHeight;
            let parentDiv = (img2.parentElement as any).parentElement;
            if (aspectRatio < 1) {
                if(parentDiv){
                    let nextDiv = parentDiv.nextElementSibling;
                    console.log(nextDiv);
                    (nextDiv as any).classList.add("cleared");
                }

                img2.classList.add('vertical-image');
                img2.classList.add('nice-img');
            } else {
                img2.classList.add('horizontal-image');
                console.log('zdjecie2poziome');
            }
        }
    }


    return (
        <div>
            <div>
                <Helmet>
                    <title>{(singleNews as any).title} - Dalbud</title>
                    <meta name="description" content={(singleNews as any).metaDescription} />
                    <meta name="keywords" content={(singleNews as any).keywords} />
                    <meta name="robots" content="index,follow" />
                    <link rel="canonical" href={hrefCanon} />
                </Helmet>

                <div className="single-news-title">{(singleNews as any).title}</div>
                <div className="single-news-container">

                    {
                        paragraphs.map((paragraph: any, key: any) => (
                            <div key={key}>


                                {key === 1 && <div className="basic-img">
                                    {!isLoading && (singleNews as any).files && (
                                        <img id="img1" className="singleNews-img"
                                             src={`${baseUrlImages}${(singleNews as any).files[0]}`}

                                        />
                                    )}
                                </div>}

                                {key === 3 && <div className="basic-img">
                                    {!isLoading && (singleNews as any).files && (
                                        <img id="img2" className="singleNews-img"
                                             src={`${baseUrlImages}${(singleNews as any).files[1]}`}
                                             onLoad={() => setIsImageRendered(true)}
                                        />

                                    )}
                                </div>}

                                <div className="singleNews-par" dangerouslySetInnerHTML={{__html: paragraph}}/>
                            </div>
                        ))
                    }


                    {/*<div>*/}
                    {/*{!isLoading && (singleNews as any).files && (*/}
                    {/*    <img id="img1" className="singleNews-img"*/}
                    {/*         src={`${baseUrlImages}${(singleNews as any).files[0]}`}/>*/}
                    {/*)}*/}
                    {/*</div>*/}


                    {/*<div>*/}
                    {/*{!isLoading && (singleNews as any).files && (*/}
                    {/*    <img id="img2" className="singleNews-img"*/}
                    {/*         src={`${baseUrlImages}${(singleNews as any).files[1]}`}/>*/}

                    {/*)}*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>

    )


}

export default SingleNews;