import React from 'react';
import { BrowserView, MobileView } from "react-device-detect";

import NewsContainer from "./components/FetchNews";
import './own.css'


export default class News extends React.Component {


    render() {
        return (
            <div id={"news"} >
                <BrowserView>

                <div className="imgBannerText">
                    <img src={require("./test_wiadomosci.png")} alt=""/>
                    {/*<div className="h1 text-center mt-5">Nowości</div>*/}

                </div>
                </BrowserView>
                <MobileView>
                    <div className="imgBannerText-mobile">
                        <img src={require("./test_wiadomosci.png")} alt=""/>
                        {/*<div className="h1 text-center mt-5">Nowości</div>*/}
                    </div>
                </MobileView>

                <NewsContainer></NewsContainer>

            </div>
        )
    }
}