import React from 'react';
import {MobileView, BrowserView} from "react-device-detect";


const Footer = () => {
    return (
        <div id={"contact"}>
            <BrowserView>
                <div className="imgBannerText" id="Dojazd">
                    <img src={require("./kontakt.png")} alt=""/>
                </div>
            </BrowserView>
            <MobileView>
                <div className="imgBannerText-mobile" id="Dojazd">
                    <img src={require("./kontakt.png")} alt=""/>
                </div>
            </MobileView>
            <div className="row">
                <div className="row">
                    <div className="col-2 deliveryBorderHide"></div>
                    <div className="col-8 deliveryBorderShow"></div>
                    <div className="col-2 deliveryBorderHide"></div>
                </div>
            </div>
            <div className="d-inline col-12 text-center">
                <div>Dalbud</div>
                <div>ul. Piastowska 62C</div>
                <div>42-200 Częstochowa</div>
                <div>Adres e-mail: <a href = "mailto: dalbud@op.pl">dalbud@op.pl</a></div>
                <div>stac. / fax. : (034) 365 03 51</div>
                <div>tel. kom. : <a href="tel:502 658 602">502 658 602</a></div>
                <div><a href="tel:601 658 602">601 658 602</a></div>
            </div>
            <div id="Kontakt">
                <div className="text-center col-12 d-none d-lg-block ">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2521.9120184894186!2d19.106303215743903!3d50.79573907952449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710b5ee57b9dca5%3A0x896f2f95ee864870!2sDalbud%20-%20Tartak%2C%20Drewno!5e0!3m2!1spl!2spl!4v1617620531543!5m2!1spl!2spl"
                            width="600" height="400" className="z-depth-1-half map-container mb-5"
                            loading="lazy"></iframe>
                </div>
                <div className="text-center col-12 d-block d-lg-none ">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2521.9120184894186!2d19.106303215743903!3d50.79573907952449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710b5ee57b9dca5%3A0x896f2f95ee864870!2sDalbud%20-%20Tartak%2C%20Drewno!5e0!3m2!1spl!2spl!4v1617620531543!5m2!1spl!2spl"
                            width="300" height="300"  className="z-depth-1-half map-container mb-5"
                            loading="lazy"></iframe>
                </div>
            </div>
        </div>
    );
};

export default Footer;