import React from "react";
import ProductCard from "./ProductCard";
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserView, MobileView} from 'react-device-detect';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
};


const Child = (parentToChild: any) => {


    let products = parentToChild.parentToChild.products;


    return (
        <div id={"products"}>
            <BrowserView>

        <div className="row d-flex justify-content-center">
            {
                products
                    .map((product:any) =>
                            <ProductCard parentToChild={product} key={product['id']} className="col w-100"/>
                    )
            }
        </div>
            </BrowserView>
            <MobileView>
                <div style={{width: '18rem'}}>
                    <Slider {...settings} >
                        {
                            products
                                .map((product:any) =>
                                    <ProductCard parentToChild={product} key={product['id']} className="col w-100 "/>
                                )
                        }
                    </Slider>
                </div>
            </MobileView>

            </div>
    )
}


export default Child;